import React from 'react'

const GraphicsDesign = () => {
    return (
        <div>
            <div>
                {/* Breadcrumb Section Start */}
                <div className="breadcrumb-wrapper section-bg bg-cover" style={{ backgroundImage: 'url("/assets/img/icon/breadcrumb-shape.webp")' }}>
                    <div className="arrow-shape">
                        <img src="/assets/img/icon/arrow-shape.webp" alt="img" />
                    </div>
                    <div className="circle-shape">
                        <img src="/assets/img/icon/circle-shape.webp" alt="img" />
                    </div>
                    <div className="container">
                        <div className="page-heading">
                            <div className="breadcrumb-sub-title">
                                <h1 className="wow fadeInUp" data-wow-delay=".3s">Graphics Design</h1>
                            </div>
                            <ul className="breadcrumb-items wow fadeInUp" data-wow-delay=".5s">
                                <li>
                                    <a href="../index.html">
                                        Home
                                    </a>
                                </li>
                                <li>
                                    <i className="fa-solid fa-angles-right" />
                                </li>
                                <li>
                                    Graphics Design
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="marquee-section fix">
                        <div className="mycustom-marque">
                            <div className="scrolling-wrap">
                                <div className="comm">
                                    <div className="cmn-textslide textitalick text-custom-storke">Services Details</div>
                                    <div className="cmn-textslide textitalick">Services Details</div>
                                    <div className="cmn-textslide textitalick text-custom-storke">Services Details</div>
                                </div>
                                <div className="comm">
                                    <div className="cmn-textslide textitalick text-custom-storke">Services Details</div>
                                    <div className="cmn-textslide textitalick">Services Details</div>
                                    <div className="cmn-textslide textitalick text-custom-storke">Services Details</div>
                                </div>
                                <div className="comm">
                                    <div className="cmn-textslide textitalick text-custom-storke">Services Details</div>
                                    <div className="cmn-textslide textitalick">Services Details</div>
                                    <div className="cmn-textslide textitalick text-custom-storke">Services Details</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Service Details Section Start */}
                <section className="service-details-section fix section-padding">
                    <div className="container">
                        <div className="service-details-wrapper">
                            <div className="row g-4">
                                <div className="col-xl-4 col-lg-4 col-md-6">
                                    <div className="details-content">
                                        <h2>Graphic Design &amp; Visual Identity</h2>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-6">
                                    <div className="details-content">
                                        <p>Our graphic design services aim to shape and elevate your brand's visual appeal, capturing attention through creative and strategic design.</p>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-6 ps-lg-5">
                                    <div className="details-content">
                                        <p>
                                            With a team of skilled designers and strategists, we bring your brand's unique story to life, creating memorable visuals that resonate with your audience.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="details-image">
                                        <img src="/assets/img/details-1.webp" alt="img" />
                                    </div>
                                    <div className="details-content">
                                        <h3 className="mb-3">Service Overview</h3>
                                        <p>
                                            Graphic design is at the heart of brand communication. From logos and color schemes to complete visual identities, our design team works to ensure that every element of your brand aligns with its mission, values, and audience preferences. We take a holistic approach, crafting designs that are not only visually appealing but also strategically impactful.
                                        </p>
                                        <div className="details-list-items">
                                            <ul>
                                                <li>+ Brand Logo Design</li>
                                                <li>+ Custom Illustrations</li>
                                                <li>+ Marketing Collateral</li>
                                            </ul>
                                            <ul>
                                                <li>+ Packaging Design</li>
                                                <li>+ Infographics</li>
                                                <li>+ Social Media Graphics</li>
                                            </ul>
                                        </div>
                                        <h3 className="mb-3">Key Features</h3>
                                        <p>
                                            Our team specializes in creating visually cohesive designs that consistently reflect your brand’s values across all platforms. We focus on brand consistency, from custom logos and typography to complete branding kits, ensuring that every touchpoint with your brand strengthens its identity. We also incorporate user-centered design into websites, ensuring they are both beautiful and intuitive.
                                        </p>
                                    </div>
                                    <div className="details-image-2">
                                        <div className="row">
                                            <div className="col-lg-8">
                                                <img src="/assets/img/details-4.webp" alt="img" />
                                            </div>
                                            <div className="col-lg-4">
                                                <img src="/assets/img/details-5.webp" alt="img" />
                                            </div>
                                        </div>
                                    </div>
                                    <p>
                                        A professionally designed website plays a vital role in building your brand’s credibility and attracting your target audience. Our web design services focus on aesthetics and functionality, ensuring seamless interaction across all devices. From e-commerce to portfolio sites, we design with scalability in mind to support your business as it grows.
                                    </p>
                                    <p className="mt-2">
                                        In addition, we offer digital marketing services to amplify your brand’s reach. With services like social media management, content creation, and pay-per-click (PPC) advertising, we help increase engagement, build loyalty, and establish a strong online presence.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> 
                {/* Faq Section Start */}
                <section className="faq-section fix section-padding">
                    <div className="container">
                        <div className="faq-wrapper">
                            <div className="row g-4">
                                <div className="col-lg-6">
                                    <div className="faq-image">
                                        <img src="https://wpriverthemes.com/HTML/agznko/assets/img/faq/01.jpg" alt="img" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="faq-content">
                                        <div className="faq-accordion">
                                            <div className="accordion" id="accordion">
                                                <div className="accordion-item mb-4 wow fadeInUp" data-wow-delay=".2s">
                                                    <h5 className="accordion-header">
                                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#faq1" aria-expanded="true" aria-controls="faq1">
                                                            What graphic design services do you offer?
                                                        </button>
                                                    </h5>
                                                    <div id="faq1" className="accordion-collapse show" data-bs-parent="#accordion">
                                                        <div className="accordion-body">
                                                            We offer a full range of graphic design services, including logo and branding,
                                                            visual identity design, website design, marketing materials, and social media
                                                            graphics. Our goal is to create visually impactful designs that resonate with your audience.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item mb-4 wow fadeInUp" data-wow-delay=".4s">
                                                    <h5 className="accordion-header">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq2" aria-expanded="false" aria-controls="faq2">
                                                            What is the typical timeline for a design project?
                                                        </button>
                                                    </h5>
                                                    <div id="faq2" className="accordion-collapse collapse" data-bs-parent="#accordion">
                                                        <div className="accordion-body">
                                                            Timelines vary depending on the project scope, but most design projects take between 2-4 weeks.
                                                            After discussing your needs, we’ll provide a detailed timeline with key milestones.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item mb-4 wow fadeInUp" data-wow-delay=".6s">
                                                    <h5 className="accordion-header">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq3" aria-expanded="false" aria-controls="faq3">
                                                            How does your design process work?
                                                        </button>
                                                    </h5>
                                                    <div id="faq3" className="accordion-collapse collapse" data-bs-parent="#accordion">
                                                        <div className="accordion-body">
                                                            Our process begins with a discovery phase to understand your brand and goals.
                                                            We then develop design concepts, refine them based on feedback, and deliver a
                                                            final product that aligns with your vision and strategy.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item mb-4 wow fadeInUp" data-wow-delay=".7s">
                                                    <h5 className="accordion-header">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq4" aria-expanded="false" aria-controls="faq4">
                                                            What do you need from me to get started?
                                                        </button>
                                                    </h5>
                                                    <div id="faq4" className="accordion-collapse collapse" data-bs-parent="#accordion">
                                                        <div className="accordion-body">
                                                            To begin, we’ll need information about your brand, target audience, and design goals.
                                                            Any existing brand materials, style guides, or inspiration can also help guide our design process.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item wow fadeInUp" data-wow-delay=".8s">
                                                    <h5 className="accordion-header">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq5" aria-expanded="false" aria-controls="faq5">
                                                            Do you work with startups and established brands alike?
                                                        </button>
                                                    </h5>
                                                    <div id="faq5" className="accordion-collapse collapse" data-bs-parent="#accordion">
                                                        <div className="accordion-body">
                                                            Yes, we work with clients at every stage, from startups launching their first brand identity
                                                            to established brands looking to refresh or expand their visual assets. Each project is tailored to the specific needs of the client.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>


        </div>
    )
}

export default GraphicsDesign