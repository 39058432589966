import React from 'react'

const BlockchainDevelopment = () => {
    return (
        <div>


            <div>
                <div className="breadcrumb-wrapper section-bg bg-cover" style={{ backgroundImage: 'url("/assets/img/icon/breadcrumb-shape.webp")' }}>
                    <div className="arrow-shape">
                        <img src="/assets/img/icon/arrow-shape.webp" alt="img" />
                    </div>
                    <div className="circle-shape">
                        <img src="/assets/img/icon/circle-shape.webp" alt="img" />
                    </div>
                    <div className="container">
                        <div className="page-heading">
                            <div className="breadcrumb-sub-title">
                                <h1 className="wow fadeInUp" data-wow-delay=".3s">Blockchain Development</h1>
                            </div>
                            <ul className="breadcrumb-items wow fadeInUp" data-wow-delay=".5s">
                                <li>
                                    <a href="../index.html">
                                        Home
                                    </a>
                                </li>
                                <li>
                                    <i className="fa-solid fa-angles-right" />
                                </li>
                                <li>
                                    Blockchain Development
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="marquee-section fix">
                        <div className="mycustom-marque">
                            <div className="scrolling-wrap">
                                <div className="comm">
                                    <div className="cmn-textslide textitalick text-custom-storke">Services Details</div>
                                    <div className="cmn-textslide textitalick">Services Details</div>
                                    <div className="cmn-textslide textitalick text-custom-storke">Services Details</div>
                                </div>
                                <div className="comm">
                                    <div className="cmn-textslide textitalick text-custom-storke">Services Details</div>
                                    <div className="cmn-textslide textitalick">Services Details</div>
                                    <div className="cmn-textslide textitalick text-custom-storke">Services Details</div>
                                </div>
                                <div className="comm">
                                    <div className="cmn-textslide textitalick text-custom-storke">Services Details</div>
                                    <div className="cmn-textslide textitalick">Services Details</div>
                                    <div className="cmn-textslide textitalick text-custom-storke">Services Details</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Service Details Section Start */}
                <section className="service-details-section fix section-padding">
                    <div className="container">
                        <div className="service-details-wrapper">
                            <div className="row g-4">
                                <div className="col-xl-4 col-lg-4 col-md-6">
                                    <div className="details-content">
                                        <h2>Blockchain Development</h2>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-6">
                                    <div className="details-content">
                                        <p>At DevAssistPro, we specialize in cutting-edge blockchain solutions designed to bring secure and scalable decentralized applications to life. Our experienced team of blockchain developers, security experts, and strategists work to ensure seamless integration and secure deployments.</p>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-6 ps-lg-5">
                                    <div className="details-content">
                                        <p>From concept to execution, we provide end-to-end services for smart contract development, wallet integration, and more, ensuring every project meets the highest industry standards.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="details-image">
                                        <img src="/assets/img/blockchain.png" alt="Blockchain Service Image" />
                                    </div>
                                    <div className="details-content">
                                        <h3 className="mb-3">Service Overview</h3>
                                        <p>
                                            Our blockchain services empower businesses to unlock the full potential of decentralized technology. We provide a holistic approach to blockchain development, including smart contract creation, security audits, and wallet integrations. Our solutions are tailored to meet unique project requirements and provide secure, user-friendly experiences for end-users.
                                        </p>
                                        <div className="details-list-items">
                                            <ul>
                                                <li>+ Smart Contract Development</li>
                                                <li>+ Decentralized Application (dApp) Development</li>
                                                <li>+ Blockchain Consulting</li>
                                            </ul>
                                            <ul>
                                                <li>+ Wallet Integration</li>
                                                <li>+ Tokenomics Design</li>
                                                <li>+ NFT Marketplace Development</li>
                                            </ul>
                                        </div>
                                        <h3 className="mb-3">Key Features</h3>
                                        <p>
                                            We provide robust smart contract solutions that enhance security, transparency, and automation. Our wallet integration services ensure seamless access for users, supporting various blockchain networks and creating a smooth user experience. Whether developing a decentralized app or launching an NFT platform, our team is dedicated to delivering secure, high-performance solutions that meet all industry standards.
                                        </p>
                                    </div>
                                    <div className="details-image-2">
                                        <div className="row">
                                            <div className="col-lg-8">
                                            <img src="/assets/img/bkeys.jpg" alt="Blockchain Service Image" />
                                            </div>
                                            <div className="col-lg-4">
                                            <img src="/assets/img/bkeys2.webp" alt="Blockchain Service Image" />
                                            </div>
                                        </div>
                                    </div>
                                    <p>
                                        With extensive experience across blockchain protocols, we provide solutions that prioritize scalability, security, and interoperability. Our smart contract development ensures trust and automation, while wallet integrations provide seamless, secure access for users across diverse platforms.
                                    </p>
                                    <p className="mt-2">
                                        We help businesses leverage blockchain for growth and innovation, whether in DeFi, NFTs, or custom dApps. Reach out to us to discuss how we can bring your blockchain vision to life with expert guidance and reliable technology.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="faq-section fix section-padding">
                    <div className="container">
                        <div className="faq-wrapper">
                            <div className="row g-4">
                                <div className="col-lg-6">
                                    <div className="faq-image">
                                    <img src="/assets/img/faq.jpg" style={{borderRadius:'10px'}} alt="Blockchain Service Image" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="faq-content">
                                        <div className="faq-accordion">
                                            <div className="accordion" id="accordion">
                                                <div className="accordion-item mb-4 wow fadeInUp" data-wow-delay=".2s">
                                                    <h5 className="accordion-header">
                                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#faq1" aria-expanded="true" aria-controls="faq1">
                                                            What blockchain development services do you offer?
                                                        </button>
                                                    </h5>
                                                    <div id="faq1" className="accordion-collapse show" data-bs-parent="#accordion">
                                                        <div className="accordion-body">
                                                            We offer a range of blockchain services, including smart contract development, decentralized app (dApp) creation, wallet integration, and blockchain consulting. Our team also specializes in tokenomics design and NFT marketplace development.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item mb-4 wow fadeInUp" data-wow-delay=".4s">
                                                    <h5 className="accordion-header">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq2" aria-expanded="false" aria-controls="faq2">
                                                            How long does a blockchain development project take?
                                                        </button>
                                                    </h5>
                                                    <div id="faq2" className="accordion-collapse collapse" data-bs-parent="#accordion">
                                                        <div className="accordion-body">
                                                            Project timelines vary based on complexity and requirements. Typically, a smart contract project can take a few weeks, while more intricate applications, like NFT marketplaces or DeFi platforms, may take several months. We provide detailed timelines after assessing your project scope.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item mb-4 wow fadeInUp" data-wow-delay=".6s">
                                                    <h5 className="accordion-header">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq3" aria-expanded="false" aria-controls="faq3">
                                                            What is your process for developing smart contracts?
                                                        </button>
                                                    </h5>
                                                    <div id="faq3" className="accordion-collapse collapse" data-bs-parent="#accordion">
                                                        <div className="accordion-body">
                                                            Our process includes understanding your business requirements, writing and testing smart contracts, and conducting thorough security audits. We prioritize code transparency, performance, and rigorous testing before deployment to ensure security and reliability.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item mb-4 wow fadeInUp" data-wow-delay=".7s">
                                                    <h5 className="accordion-header">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq4" aria-expanded="false" aria-controls="faq4">
                                                            How do you integrate blockchain wallets into applications?
                                                        </button>
                                                    </h5>
                                                    <div id="faq4" className="accordion-collapse collapse" data-bs-parent="#accordion">
                                                        <div className="accordion-body">
                                                            We provide secure wallet integration for both web and mobile platforms, enabling users to interact with your dApp or platform seamlessly. We support various wallets, including MetaMask and Coinbase Wallet, and ensure compatibility with popular blockchain networks.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item wow fadeInUp" data-wow-delay=".8s">
                                                    <h5 className="accordion-header">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq5" aria-expanded="false" aria-controls="faq5">
                                                            Can you work with startups and established businesses?
                                                        </button>
                                                    </h5>
                                                    <div id="faq5" className="accordion-collapse collapse" data-bs-parent="#accordion">
                                                        <div className="accordion-body">
                                                            Absolutely. We have experience working with both startups looking to break into the blockchain space and established businesses aiming to incorporate blockchain technology. We tailor our approach to fit the specific needs and goals of each client, regardless of their scale.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>

        </div>
    )
}

export default BlockchainDevelopment