import React from 'react'

const Contact = () => {
    return (
        <div>
            <div>
                {/* Breadcrumb Section Start */}
                <div className="breadcrumb-wrapper section-bg bg-cover" style={{ backgroundImage: 'url("/assets/img/icon/breadcrumb-shape.webp")' }}>
                    <div className="arrow-shape">
                        <img src="/assets/img/icon/arrow-shape.webp" alt="img" />
                    </div>
                    <div className="circle-shape">
                        <img src="/assets/img/icon/circle-shape.webp" alt="img" />
                    </div>
                    <div className="container">
                        <div className="page-heading">
                            <div className="breadcrumb-sub-title">
                                <h1 className="wow fadeInUp" data-wow-delay=".3s">CONTACT US</h1>
                            </div>
                            <ul className="breadcrumb-items wow fadeInUp" data-wow-delay=".5s">
                                <li>
                                    <a href="index.html">
                                        Home
                                    </a>
                                </li>
                                <li>
                                    <i className="fa-solid fa-angles-right" />
                                </li>
                                <li>
                                    Contact Us
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="marquee-section fix">
                        <div className="mycustom-marque">
                            <div className="scrolling-wrap">
                                <div className="comm">
                                    <div className="cmn-textslide textitalick text-custom-storke">CONTACT US</div>
                                    <div className="cmn-textslide textitalick">CONTACT US</div>
                                    <div className="cmn-textslide textitalick text-custom-storke">CONTACT US</div>
                                </div>
                                <div className="comm">
                                    <div className="cmn-textslide textitalick text-custom-storke">CONTACT US</div>
                                    <div className="cmn-textslide textitalick">CONTACT US</div>
                                    <div className="cmn-textslide textitalick text-custom-storke">CONTACT US</div>
                                </div>
                                <div className="comm">
                                    <div className="cmn-textslide textitalick text-custom-storke">CONTACT US</div>
                                    <div className="cmn-textslide textitalick">CONTACT US</div>
                                    <div className="cmn-textslide textitalick text-custom-storke">CONTACT US</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Contact Info Section Start */}
                <section className="contact-info-section fix section-padding" style={{ paddingTop: '30px' }}>
                    <h1 className='text-center mb-1' style={{ fontSize: '60px' }}>Concerned about trust?</h1>
                    <h3 className='text-center mb-5'>We encourage placing your first order on Fiverr to build trust and ensure a smooth experience.</h3>
                    <div className="container">
                        <div className="row g-4">
                            <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay=".3s">
                                <div className="contact-box-items">
                                    <div className="icon">
                                        <a target='_blank' href='https://www.fiverr.com/alamin_labs'>
                                            <img src="/assets/img/fiverr.webp" width={85} alt="img" />
                                        </a>
                                    </div>
                                    <div className="content">
                                        <h3>Contact On Fiverr </h3>
                                        <a target='_blank' href='https://www.fiverr.com/alamin_labs'>
                                            <button className='theme-btn wow fadeInUp' style={{ padding: '5px 25px', backgroundColor: '#25d366' }}>Go <span><i class="fa-solid fa-arrow-right"></i></span> </button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay=".5s">
                                <div className="contact-box-items">

                                    <div className="icon">
                                        <a href="tel:+8801614643814">
                                            <img src="/assets/img/whatsapp.png" style={{ borderRadius: '20px' }} width={85} alt="img" />
                                        </a>
                                    </div>
                                    <div className="content">
                                        <h3>Contact On WhatsApp </h3>
                                        <a href="tel:+8801614643814">
                                            <button className='theme-btn wow fadeInUp' style={{ padding: '5px 25px', backgroundColor: '#25d366' }}>Go <span><i class="fa-solid fa-arrow-right"></i></span> </button>
                                        </a>
                                    </div>

                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay=".7s">
                                <div className="contact-box-items">

                                    <div className="icon">
                                        <a target='_blank' href='mailto:softdev.alamin@gmail.com'>
                                            <img src="/assets/img/gmail.svg" style={{ borderRadius: '20px', background: 'white' }} width={85} alt="img" />
                                        </a>
                                    </div>
                                    <div className="content">
                                        <h3>Contact On Gmail </h3>
                                        <a href="mailto:softdev.alamin@gmail.com">
                                            <button className='theme-btn wow fadeInUp' style={{ padding: '5px 25px', backgroundColor: '#25d366' }}>Go <span><i class="fa-solid fa-arrow-right"></i></span> </button>
                                        </a>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Contact Section Start */}
                {/* <section className="contact-section fix section-padding pt-0">
                    <div className="container">
                        <div className="contact-wrapper">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="map-items">
                                        <div className="googpemap">
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6678.7619084840835!2d144.9618311901502!3d-37.81450084255415!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642b4758afc1d%3A0x3119cc820fdfc62e!2sEnvato!5e0!3m2!1sen!2sbd!4v1641984054261!5m2!1sen!2sbd" style={{ border: 0 }} allowFullScreen loading="lazy" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="contact-content">
                                        <div className="section-title text-center">
                                            <h6 className="wow fadeInUp">Contact Us</h6>
                                            <h2 className="wow fadeInUp" data-wow-delay=".3s">Get in touch</h2>
                                        </div>
                                        <form action="contact.php" id="contact-form" method="POST" className="contact-form-items mt-3 mt-md-0">
                                            <div className="row g-4">
                                                <div className="col-lg-6 wow fadeInUp" data-wow-delay=".3s">
                                                    <div className="form-clt">
                                                        <span>Your name*</span>
                                                        <input type="text" name="name" id="name" placeholder="Your Name" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 wow fadeInUp" data-wow-delay=".5s">
                                                    <div className="form-clt">
                                                        <span>Your Email*</span>
                                                        <input type="text" name="email" id="email" placeholder="Your Email" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 wow fadeInUp" data-wow-delay=".7s">
                                                    <div className="form-clt">
                                                        <span>Write Message*</span>
                                                        <textarea name="message" id="message" placeholder="Write Message" defaultValue={""} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-7 wow fadeInUp" data-wow-delay=".9s">
                                                    <button type="submit" className="theme-btn">
                                                        Send Message
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
            </div>

        </div>
    )
}

export default Contact