import React from 'react'

const WebDevelopment = () => {
    return (
        <div>



            <div>
                {/* Breadcrumb Section Start */}
                <div className="breadcrumb-wrapper section-bg bg-cover" style={{ backgroundImage: 'url("/assets/img/icon/breadcrumb-shape.webp")' }}>
                    <div className="arrow-shape">
                        <img src="/assets/img/icon/arrow-shape.webp" alt="img" />
                    </div>
                    <div className="circle-shape">
                        <img src="/assets/img/icon/circle-shape.webp" alt="img" />
                    </div>
                    <div className="container">
                        <div className="page-heading">
                            <div className="breadcrumb-sub-title">
                                <h1 className="wow fadeInUp" data-wow-delay=".3s">Web Development</h1>
                            </div>
                            <ul className="breadcrumb-items wow fadeInUp" data-wow-delay=".5s">
                                <li>
                                    <a href="../index.html">
                                        Home
                                    </a>
                                </li>
                                <li>
                                    <i className="fa-solid fa-angles-right" />
                                </li>
                                <li>
                                    Web Development
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="marquee-section fix">
                        <div className="mycustom-marque">
                            <div className="scrolling-wrap">
                                <div className="comm">
                                    <div className="cmn-textslide textitalick text-custom-storke">Services Details</div>
                                    <div className="cmn-textslide textitalick">Services Details</div>
                                    <div className="cmn-textslide textitalick text-custom-storke">Services Details</div>
                                </div>
                                <div className="comm">
                                    <div className="cmn-textslide textitalick text-custom-storke">Services Details</div>
                                    <div className="cmn-textslide textitalick">Services Details</div>
                                    <div className="cmn-textslide textitalick text-custom-storke">Services Details</div>
                                </div>
                                <div className="comm">
                                    <div className="cmn-textslide textitalick text-custom-storke">Services Details</div>
                                    <div className="cmn-textslide textitalick">Services Details</div>
                                    <div className="cmn-textslide textitalick text-custom-storke">Services Details</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Service Details Section Start */}
                <section className="service-details-section fix section-padding">
                    <div className="container">
                        <div className="service-details-wrapper">
                            <div className="row g-4">
                                <div className="col-xl-4 col-lg-4 col-md-6">
                                    <div className="details-content">
                                        <h2>Full-stack Web App Development</h2>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-6">
                                    <div className="details-content">
                                        <p>At DevUnicornLabs, we leverage cutting-edge technologies to develop robust, scalable, and intelligent web applications. Our services are designed to drive growth and enhance operational efficiency through seamless digital solutions.</p>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-6 ps-lg-5">
                                    <div className="details-content">
                                        <p>Our experienced team of developers, AI experts, and strategists collaborates to create impactful solutions that align with your unique business goals, API development, frontend frameworks, and backend systems.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="details-image">
                                        <img src="/assets/img/web.jpg" alt="img" />
                                    </div>
                                    <div className="details-content">
                                        <h3 className="mb-3">Service Overview</h3>
                                        <p>
                                            Our web development service provides a comprehensive solution for businesses seeking to establish a powerful online presence. From frontend design and backend engineering to AI-driven enhancements, we focus on creating applications that are visually appealing, fast, and future-ready. Our team specializes in technologies like React, Node.js, Python, Django, and Material UI, and we deliver fully functional, user-centered applications that work seamlessly across all devices.
                                        </p>
                                        <div className="details-list-items">
                                            <ul>
                                                <li>+ API Development</li>
                                                <li>+ Cloud Migration</li>
                                                <li>+ Frontend Development with React and Material UI</li>
                                            </ul>
                                            <ul>
                                                <li>+ Backend Development with Node.js, Python, Django</li>
                                                <li>+ AI &amp; Machine Learning Integration</li>
                                                <li>+ Custom WordPress Solutions</li>
                                            </ul>
                                        </div>
                                        <h3 className="mb-3">Key Features</h3>
                                        <p>
                                            We build feature-rich web applications tailored to your business's unique needs. From integrating AI-driven insights to designing responsive layouts and building secure, scalable backends, we bring a blend of innovation and efficiency to every project. Our team is proficient in developing custom APIs, implementing machine learning models, and creating sophisticated user interfaces that resonate with your target audience and drive engagement.
                                        </p>
                                    </div>
                                    <div className="details-image-2">
                                        <div className="row">
                                            <div className="col-lg-8">
                                                <img src="/assets/img/webcode.webp" height={400} alt="img" />
                                            </div>
                                            <div className="col-lg-4">
                                            <img src="/assets/img/service.jpg" height={400} alt="img" />
                                            </div>
                                        </div>
                                    </div>
                                    <p>
                                        At DevUnicornLabs, we understand that a strong online presence is essential. Our web development services are designed to empower your business with high-performing, secure, and aesthetically pleasing websites that provide an exceptional user experience. Whether you’re a startup or an established enterprise, our team is equipped to handle projects of any size and complexity, integrating tools like WordPress for custom CMS needs, and React for dynamic, interactive interfaces.
                                    </p>
                                    <p className="mt-2">
                                        Additionally, we provide full support for backend systems built on Node.js, Django, and Python, ensuring your web application remains fast, secure, and highly functional. With our expertise in both frontend and backend development, combined with AI-driven features, your application is in capable hands, ready to make an impactful impression on your users.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> 
            </div>
        </div>
    )
}

export default WebDevelopment