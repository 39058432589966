import React from 'react'
import { Link } from 'react-router-dom'

const NotFound = () => {
    return (
        <div>
            <div>
                {/* Breadcrumb Section Start */}
                <div className="breadcrumb-wrapper section-bg bg-cover" style={{ backgroundImage: 'url("/assets/img/icon/breadcrumb-shape.webp")' }}>
                    <div className="arrow-shape">
                        <img src="/assets/img/icon/arrow-shape.webp" alt="img" />
                    </div>
                    <div className="circle-shape">
                        <img src="/assets/img/icon/circle-shape.webp" alt="img" />
                    </div>
                    <div className="container">
                        <div className="page-heading">
                            <div className="breadcrumb-sub-title">
                                <h1 className="wow fadeInUp" data-wow-delay=".3s">Error 404</h1>
                            </div>
                            <ul className="breadcrumb-items wow fadeInUp" data-wow-delay=".5s">
                                <li>
                                    <a href="index.html">
                                        Home
                                    </a>
                                </li>
                                <li>
                                    <i className="fa-solid fa-angles-right" />
                                </li>
                                <li>
                                    Error 404
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="marquee-section fix">
                        <div className="mycustom-marque">
                            <div className="scrolling-wrap">
                                <div className="comm">
                                    <div className="cmn-textslide textitalick text-custom-storke">Error 404</div>
                                    <div className="cmn-textslide textitalick">Error 404</div>
                                    <div className="cmn-textslide textitalick text-custom-storke">Error 404</div>
                                </div>
                                <div className="comm">
                                    <div className="cmn-textslide textitalick text-custom-storke">Error 404</div>
                                    <div className="cmn-textslide textitalick">Error 404</div>
                                    <div className="cmn-textslide textitalick text-custom-storke">Error 404</div>
                                </div>
                                <div className="comm">
                                    <div className="cmn-textslide textitalick text-custom-storke">Error 404</div>
                                    <div className="cmn-textslide textitalick">Error 404</div>
                                    <div className="cmn-textslide textitalick text-custom-storke">Error 404</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* 404 Error Section Start */}
                <section className="error-section section-padding fix">
                    <div className="container">
                        <div className="error-content text-center">
                            <h2 className="wow fadeInUp" data-wow-delay=".3s">4<span>0</span>4</h2>
                            <h3 className="wow fadeInUp" data-wow-delay=".5s">Look Like You’re Lost</h3>
                            <p>
                                Sorry, we couldn't find the page you where looking for. We suggest that you return to homepage.
                            </p>
                            <Link to="/" className="theme-btn style-line-height mt-5 wow fadeInUp" data-wow-delay=".7s">
                                Back To Home
                            </Link>
                        </div>
                    </div>
                </section>
            </div>

        </div>
    )
}

export default NotFound