import React from 'react' 
import { Link } from 'react-router-dom'
import Clients from '../Components/Clients'
const Home = () => {
    return (
        <>
            <div>
                {/* Hero Section Start */}
                <section className="hero-section hero-1 section-padding">
                    <div className="container">
                        <div className="hero-content">
                            <h1 className="wow fadeInUp" data-wow-delay=".3s" style={{ zIndex: 9, position: 'relative' }}>
                                <span>DevUnicornLabs</span> Your Partner <br />
                                <img src="/assets/img/client-img.jpg" alt="img" /> in Web
                                Development
                            </h1>
                            <p className="wow fadeInUp" data-wow-delay=".3s">Transforming ideas into powerful digital solutions. Let’s
                                build your success together!</p>
                            <div className="row">
                                <div className="col-lg-12 wow fadeInUp" data-wow-delay=".4s">
                                    <div className="hero-image">
                                        <img src="assets/img/banner.png" alt="img" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="hero-counter float-bob-y ">
                            <div className="counter-items">
                                <h2><span className="count">6</span>+</h2>
                                <p>YEAR EXPERIENCE</p>
                            </div>
                            <div className="counter-items">
                                <h2><span className="count">64</span></h2>
                                <p>HAPPY CUSTOMER</p>
                            </div>
                            <div className="counter-items">
                                <h2><span className="count">342</span></h2>
                                <p>PROJECTS COMPLETED</p>
                            </div>
                        </div>
                        <div className="circle-item">
                            <div className="circle-text">
                                <img src="./assets/img/rounded.png" width={155} alt="DevUnicornLabs" />
                            </div>
                            <div className="border-line">
                                <Link onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} to="/about" className="arrow-icon">
                                    <i className="fa-sharp fa-solid fa-arrow-right" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Brand Section Start */}
                <Clients/>
                {/* About Section Start */}
                <section className="about-section fix section-padding section-bg">
                    <div className="container">
                        <div className="section-title-area">
                            <div className="section-title">
                                <h6 className="wow fadeInUp">About Us</h6>
                                <h2 className="wow fadeInUp" data-wow-delay=".3s">Empowering Your <span>Vision </span> <br />
                                    with Tailored Digital Solutions</h2>
                            </div>
                            <p className="wow fadeInUp" data-wow-delay=".5s">
                                Crafting digital web solutions to bring your <br />Business Up.
                            </p>
                        </div>
                        <div className="about-wrapper">
                            <div className="row justify-content-between align-items-center">
                                <div className="col-lg-7">
                                    <div className="about-image-items">
                                        <div className="row g-4 align-items-center">
                                            <div className="col-md-6 wow fadeInUp" data-wow-delay=".3s">
                                                <div className="support-content">
                                                    <h3>24/7 Support</h3>
                                                    <div className="text-area">
                                                        <p>We provide 24/7 service <br /> to our customers</p>
                                                        <Link onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} to="/about" className="icon">
                                                            <i className="fa-sharp fa-solid fa-arrow-right" />
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="about-image">
                                                    <img src="./assets/img/intro.png" alt="img" />
                                                </div>
                                            </div>
                                            <div className="col-md-6 wow fadeInUp" data-wow-delay=".5s">
                                                <div className="about-image-2">
                                                    <div className="about-image style-2">
                                                        <img src="./assets/img/we.png" alt="img" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div className="about-content">
                                        <div className="about-icon-items wow fadeInUp" data-wow-delay=".3s">
                                            <div className="icon">
                                                <i className="fa-solid fa-dollar-sign" />
                                                <div className="line-bar">
                                                    <img src="/assets/img/icon/line.webp" alt="img" />
                                                </div>
                                            </div>
                                            <div className="content">
                                                <h3>Our Mission</h3>
                                                <p>
                                                    To empower businesses by delivering innovative, reliable web and app solutions
                                                    that drive growth and success in a digital-first world.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="about-icon-items wow fadeInUp" data-wow-delay=".5s">
                                            <div className="icon">
                                                <i className="fa-regular fa-star" />
                                                <div className="line-bar">
                                                    <img src="/assets/img/icon/line.webp" alt="img" />
                                                </div>
                                            </div>
                                            <div className="content">
                                                <h3>Our Vision</h3>
                                                <p>
                                                    To become a global leader in digital transformation, helping businesses unlock
                                                    their full potential through cutting-edge technology and exceptional user
                                                    experiences.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="about-icon-items wow fadeInUp" data-wow-delay=".3s">
                                            <div className="icon">
                                                <i className="fa-solid fa-shield-halved" />
                                                <div className="line-bar">
                                                    <img src="/assets/img/icon/line.webp" alt="img" />
                                                </div>
                                            </div>
                                            <div className="content">
                                                <h3>What Sets Us Apart</h3>
                                                <p>
                                                    Our personalized approach, attention to detail, and commitment to delivering
                                                    seamless, high-quality solutions that exceed client expectations every time.
                                                </p>
                                            </div>
                                        </div>
                                        <Link onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} to="/about-us" className="theme-btn wow fadeInUp" data-wow-delay=".5s">More About Us</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Service Section Start */}
                <section className="service-section fix section-padding">
                    <div className="container">
                        <div className="section-title-area">
                            <div className="section-title">
                                <h6 className="wow fadeInUp">Our Services</h6>
                                <h2 className="wow fadeInUp" data-wow-delay=".3s">Our Spectrum of <span>Solutions</span></h2>
                            </div>
                            <Link onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} to="/services" className="theme-btn wow fadeInUp" data-wow-delay=".5s">See All Services</Link>
                        </div>
                        <div className="row g-0">
                            <div className="col-xl-3 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay=".2s">
                                <div className="service-box-items">
                                    <div className="icon">
                                        <i className="fa-solid fa-terminal" />
                                    </div>
                                    <div className="content">
                                        <h3><Link to="/web-development">Web Development</Link></h3>
                                        <p>
                                            Creating responsive, user-friendly websites that deliver exceptional user experiences
                                            and drive business growth.
                                        </p>
                                        <div className="service-btn">
                                            <Link onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} to="/web-development" className="arrow-icon"><img src="/assets/img/icon/arrow.svg" alt="img" /></Link>
                                            <Link onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} to="/web-development" className="link-btn">Read more</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay=".4s">
                                <div className="service-box-items">
                                    <div className="icon">
                                        <i className="fa-solid fa-link" />
                                    </div>
                                    <div className="content">
                                        <h3><Link to="/blockchain-development">Blockchain Development</Link></h3>
                                        <p>
                                            Building secure, decentralized blockchain solutions tailored to your business needs,
                                            ensuring transparency and innovation.
                                        </p>
                                        <div className="service-btn">
                                            <Link onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} to="/blockchain-development" className="arrow-icon"><img src="/assets/img/icon/arrow.svg" alt="img" /></Link>
                                            <Link onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} to="/blockchain-development" className="link-btn">Read more</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay=".6s">
                                <div className="service-box-items">
                                    <div className="icon">
                                        <i className="fa-solid fa-palette" />
                                    </div>
                                    <div className="content">
                                        <h3><Link to="/graphics-design">Graphics Design</Link></h3>
                                        <p>
                                            Designing visually stunning graphics that capture your brand’s identity and engage your
                                            audience.
                                        </p>
                                        <div className="service-btn">
                                            <Link onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} to="/graphics-design" className="arrow-icon"><img src="/assets/img/icon/arrow.svg" alt="img" /></Link>
                                            <Link onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} to="/graphics-design" className="link-btn">Read more</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay=".8s">
                                <div className="service-box-items border-none">
                                    <div className="icon">
                                        <i className="fa-solid fa-bullhorn" />
                                    </div>
                                    <div className="content">
                                        <h3><Link to="/digital-marketing">Digital Marketing</Link></h3>
                                        <p>
                                            Strategizing and executing impactful digital marketing campaigns to boost your online
                                            presence and maximize reach.
                                        </p>
                                        <div className="service-btn">
                                            <Link onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} to="/digital-marketing" className="arrow-icon"><img src="/assets/img/icon/arrow.svg" alt="img" /></Link>
                                            <Link onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} to="/digital-marketing" className="link-btn">Read more</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Portfolio Section Start */}
                <section className="portfolio-section fix section-bg section-padding">
                    <div className="container">
                        <div className="section-title-area">
                            <div className="section-title">
                                <h6 className="wow fadeInUp">Our Portfolio</h6>
                                <h2 className="wow fadeInUp" data-wow-delay=".3s">Some recent <span>work</span></h2>
                            </div>
                            <Link onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} to="/project" className="theme-btn wow fadeInUp" data-wow-delay=".5s">See All Works</Link>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-box-items wow fadeInUp" data-wow-delay=".3s">
                                    <div className="project-wrap">
                                        <div className="content">
                                            <span>Website, Branding</span>
                                            <h3><Link to="/project"> Florida Real Estate Web App<br /> In Florida</Link></h3>
                                        </div>
                                        <p>
                                            Created a user-friendly web app for Florida real estate, featuring property listings,
                                            advanced search options, and responsive design for seamless browsing on all devices.
                                        </p>
                                    </div>
                                    <Link onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} to="/project" className="radius-btn">
                                        <i className="fa-sharp fa-solid fa-arrow-right" />
                                        View Details
                                    </Link>
                                    <div className="project-hover d-none d-md-block bg-cover" style={{ backgroundImage: 'url("/assets/img/logo/logo.png")' }}>
                                    </div>
                                </div>
                                <div className="portfolio-box-items wow fadeInUp" data-wow-delay=".3s">
                                    <div className="project-wrap">
                                        <div className="content">
                                            <span>Blockchain, Backend Development</span>
                                            <h3><Link to="/project">Crypto Staking Platform</Link></h3>
                                        </div>
                                        <p>
                                            Built a secure platform for users to stake cryptocurrencies, featuring real-time rewards
                                            tracking and an intuitive interface.</p>
                                        {/* https://www.coinsquare.com/ */}
                                    </div>
                                    <Link onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} to="/project" className="radius-btn">
                                        <i className="fa-sharp fa-solid fa-arrow-right" />
                                        View Details
                                    </Link>
                                    <div className="project-hover d-none d-md-block bg-cover" style={{ backgroundImage: 'url("/assets/img/logo/logo.png")' }}>
                                    </div>
                                </div>
                                <div className="portfolio-box-items wow fadeInUp" data-wow-delay=".3s">
                                    <div className="project-wrap">
                                        <div className="content">
                                            <span>Digital Marketing, Graphics</span>
                                            <h3><Link to="/project">Building Brands from <br /> the Ground Up</Link></h3>
                                        </div>
                                        <p>
                                            Provided premium car detailing services in St. Pete, enhancing vehicle aesthetics and
                                            protecting investments for a flawless finish.
                                        </p>
                                    </div>
                                    <Link onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} to="/project" className="radius-btn">
                                        <i className="fa-sharp fa-solid fa-arrow-right" />
                                        View Details
                                    </Link>
                                    <div className="project-hover d-none d-md-block bg-cover" style={{ backgroundImage: 'url("/assets/img/logo/logo.png")' }}>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> 
                {/* Testimonial Section Start */}
                <section className="testimonial-section fix section-bg section-padding">
                    <div className="container">
                        <div className="section-title text-center">
                            <h6 className="wow fadeInUp">Testimonial</h6>
                            <h2 className="wow fadeInUp" data-wow-delay=".3s">Client <span>Feedback</span></h2>
                        </div>
                        <div className="swiper testimonial-slider">
                            <div className="swiper-wrapper">
                                <div className="swiper-slide">
                                    <div className="testimonial-box-items">
                                        <div className="client-info">
                                            <div className="client-img">
                                                <img src="/assets/img/03.webp" alt="img" />
                                            </div>
                                            <div className="content">
                                                <h5>Peter</h5>
                                                <span>Coinlabs Technologies</span>
                                            </div>
                                        </div>
                                        <p>
                                            DevUnicornLabs did an amazing job on my real estate website. The team was responsive,
                                            delivered on time, and exceeded my expectations. Highly recommended! "
                                        </p>
                                        <div className="testi-bottom">
                                            <div className="star">
                                                <i className="fa-solid fa-star" />
                                                <i className="fa-solid fa-star" />
                                                <i className="fa-solid fa-star" />
                                                <i className="fa-solid fa-star" />
                                                <i className="fa-solid fa-star-half-stroke" />
                                            </div>
                                            <div className="quote-icon">
                                                <i className="fa-solid fa-quote-right" style={{ color: '#f90052', fontSize: '55px' }} />
                                            </div>
                                        </div>
                                        <div className="icon">
                                            <img src="/assets/img/icon/icon.webp" alt="img" />
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="testimonial-box-items">
                                        <div className="client-info">
                                            <div className="client-img">
                                                <img src="/assets/img/06.webp" alt="img" />
                                            </div>
                                            <div className="content">
                                                <h5>Juan David</h5>
                                                <span>Technology Manager</span>
                                            </div>
                                        </div>
                                        <p>
                                            Super happy with the website DevUnicornLabs built for me. Great communication, fast
                                            delivery, and exactly what I needed for my business! "
                                        </p>
                                        <div className="testi-bottom">
                                            <div className="star">
                                                <i className="fa-solid fa-star" />
                                                <i className="fa-solid fa-star" />
                                                <i className="fa-solid fa-star" />
                                                <i className="fa-solid fa-star" />
                                                <i className="fa-solid fa-star-half-stroke" />
                                            </div>
                                            <div className="quote-icon">
                                                <i className="fa-solid fa-quote-right" style={{ color: '#f90052', fontSize: '55px' }} />
                                            </div>
                                        </div>
                                        <div className="icon">
                                            <img src="/assets/img/icon/icon.webp" alt="img" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> 
            </div>

        </>
    )
}

export default Home