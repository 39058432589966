import React from 'react'

const Faq = () => {
    return (
        <div>
            <div>
                {/* Breadcrumb Section Start */}
                <div className="breadcrumb-wrapper section-bg bg-cover" style={{ backgroundImage: 'url("/assets/img/icon/breadcrumb-shape.webp")' }}>
                    <div className="arrow-shape">
                        <img src="/assets/img/icon/arrow-shape.webp" alt="img" />
                    </div>
                    <div className="circle-shape">
                        <img src="/assets/img/icon/circle-shape.webp" alt="img" />
                    </div>
                    <div className="container">
                        <div className="page-heading">
                            <div className="breadcrumb-sub-title">
                                <h1 className="wow fadeInUp" data-wow-delay=".3s">FAQ</h1>
                            </div>
                            <ul className="breadcrumb-items wow fadeInUp" data-wow-delay=".5s">
                                <li>
                                    <a href="index.html">
                                        Home
                                    </a>
                                </li>
                                <li>
                                    <i className="fa-solid fa-angles-right" />
                                </li>
                                <li>
                                    Faq
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="marquee-section fix">
                        <div className="mycustom-marque">
                            <div className="scrolling-wrap">
                                <div className="comm">
                                    <div className="cmn-textslide textitalick text-custom-storke">FAQ</div>
                                    <div className="cmn-textslide textitalick">FAQ</div>
                                    <div className="cmn-textslide textitalick text-custom-storke">FAQ</div>
                                </div>
                                <div className="comm">
                                    <div className="cmn-textslide textitalick text-custom-storke">FAQ</div>
                                    <div className="cmn-textslide textitalick">FAQ</div>
                                    <div className="cmn-textslide textitalick text-custom-storke">FAQ</div>
                                </div>
                                <div className="comm">
                                    <div className="cmn-textslide textitalick text-custom-storke">FAQ</div>
                                    <div className="cmn-textslide textitalick">FAQ</div>
                                    <div className="cmn-textslide textitalick text-custom-storke">FAQ</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Faq Section Start */}
                <section className="faq-section fix section-padding">
                    <div className="container">
                        <div className="faq-wrapper">
                            <div className="row g-4">
                                <div className="col-lg-6">
                                    <div className="faq-content-2">
                                        <div className="section-title">
                                            <h6>faq</h6>
                                            <h2>
                                                Frequently <br />
                                                Asked <span>Questions</span>
                                            </h2>
                                            <p className="mt-2">Still have a question in mind? Contact us directly!</p>
                                        </div>
                                        <a href="contact.html" className="theme-btn mt-3 mt-md-0">Contact Us</a>
                                        <div className="faq-client-items">
                                            <div className="client-info">
                                                <img src="/assets/img/01.webp" alt="img" />
                                                <div className="content">
                                                    <h4>Guy Hawkins</h4>
                                                    <span>Austin</span>
                                                </div>
                                            </div>
                                            <p>
                                                DevUnicornLabstransformed our ideas into a stunning reality. Their innovative approach and dedication to our project  with the results."
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="faq-content">
                                        <div className="faq-accordion">
                                            <div className="accordion" id="accordion">
                                                <div className="accordion-item mb-4 wow fadeInUp" data-wow-delay=".2s">
                                                    <h5 className="accordion-header">
                                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#faq1" aria-expanded="true" aria-controls="faq1">
                                                            What services does your creative agency offer?
                                                        </button>
                                                    </h5>
                                                    <div id="faq1" className="accordion-collapse show" data-bs-parent="#accordion">
                                                        <div className="accordion-body">
                                                            Our process for creating a visual identity begins with in-depth research and brand discovery. We then develop creative concepts, refine them through  impactful design that resonates with your audience.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item mb-4 wow fadeInUp" data-wow-delay=".4s">
                                                    <h5 className="accordion-header">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq2" aria-expanded="false" aria-controls="faq2">
                                                            What is the typical timeline for a project?
                                                        </button>
                                                    </h5>
                                                    <div id="faq2" className="accordion-collapse collapse" data-bs-parent="#accordion">
                                                        <div className="accordion-body">
                                                            Our process for creating a visual identity begins with in-depth research and brand discovery. We then develop creative concepts, refine them through  impactful design that resonates with your audience.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item mb-4 wow fadeInUp" data-wow-delay=".6s">
                                                    <h5 className="accordion-header">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq3" aria-expanded="false" aria-controls="faq3">
                                                            What is your process for creating a visual identity?
                                                        </button>
                                                    </h5>
                                                    <div id="faq3" className="accordion-collapse collapse" data-bs-parent="#accordion">
                                                        <div className="accordion-body">
                                                            Our process for creating a visual identity begins with in-depth research and brand discovery. We then develop creative concepts, refine them through  impactful design that resonates with your audience.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item mb-4 wow fadeInUp" data-wow-delay=".7s">
                                                    <h5 className="accordion-header">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq4" aria-expanded="false" aria-controls="faq4">
                                                            How do we get started with your agency?
                                                        </button>
                                                    </h5>
                                                    <div id="faq4" className="accordion-collapse collapse" data-bs-parent="#accordion">
                                                        <div className="accordion-body">
                                                            Our process for creating a visual identity begins with in-depth research and brand discovery. We then develop creative concepts, refine them through  impactful design that resonates with your audience.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item wow fadeInUp" data-wow-delay=".8s">
                                                    <h5 className="accordion-header">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq5" aria-expanded="false" aria-controls="faq5">
                                                            Do you work with startups or established brands?
                                                        </button>
                                                    </h5>
                                                    <div id="faq5" className="accordion-collapse collapse" data-bs-parent="#accordion">
                                                        <div className="accordion-body">
                                                            Our process for creating a visual identity begins with in-depth research and brand discovery. We then develop creative concepts, refine them through  impactful design that resonates with your audience.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Faq