import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { scrollTop } from '../utils/functions'

const Service = () => {
    const [currentPage, setCurrentPage] = useState(1)

    return (
        <>
            <div>
                {/* Breadcrumb Section Start */}
                <div className="breadcrumb-wrapper section-bg bg-cover" style={{ backgroundImage: 'url("/assets/img/icon/breadcrumb-shape.webp")' }}>
                    <div className="arrow-shape">
                        <img src="/assets/img/icon/arrow-shape.webp" alt="img" />
                    </div>
                    <div className="circle-shape">
                        <img src="/assets/img/icon/circle-shape.webp" alt="img" />
                    </div>
                    <div className="container">
                        <div className="page-heading">
                            <div className="breadcrumb-sub-title">
                                <h1 className="wow fadeInUp" data-wow-delay=".3s"> SERVICES </h1>
                            </div>
                            <ul className="breadcrumb-items wow fadeInUp" data-wow-delay=".5s">
                                <li>
                                    <Link to="/index">
                                        Home
                                    </Link>
                                </li>
                                <li>
                                    <i className="fa-solid fa-angles-right" />
                                </li>
                                <li>
                                    SERVICES
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="marquee-section fix">
                        <div className="mycustom-marque">
                            <div className="scrolling-wrap">
                                <div className="comm">
                                    <div className="cmn-textslide textitalick text-custom-storke">SERVICES</div>
                                    <div className="cmn-textslide textitalick">SERVICES</div>
                                    <div className="cmn-textslide textitalick text-custom-storke">SERVICES</div>
                                </div>
                                <div className="comm">
                                    <div className="cmn-textslide textitalick text-custom-storke">SERVICES</div>
                                    <div className="cmn-textslide textitalick">SERVICES</div>
                                    <div className="cmn-textslide textitalick text-custom-storke">SERVICES</div>
                                </div>
                                <div className="comm">
                                    <div className="cmn-textslide textitalick text-custom-storke">SERVICES</div>
                                    <div className="cmn-textslide textitalick">SERVICES</div>
                                    <div className="cmn-textslide textitalick text-custom-storke">SERVICES</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Service Section Start */}
                <section className="service-section fix section-padding" style={{ paddingTop: "50px" }}>
                    <h2 style={{ textAlign: 'center', marginBottom: '0px' }}>
                        Industries Where We Provide Services
                    </h2>
                    <p style={{ textAlign: 'center', marginBottom: '50px' }}>
                        Our expertise spans a diverse range of industries, delivering tailored solutions that drive success and innovation.
                    </p>

                    <div className="container">
                        <div className="row g-4">
                            {
                                currentPage == 1 ?
                                    <>

                                        <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay=".3s">
                                            <div className="service-box-items style-2 mt-0">
                                                <div className="icon">
                                                    <img src="/assets/img/icon/realestate.png" width={45} alt="Real estate icon" />
                                                </div>
                                                <div className="content">
                                                    <h3>Real Estate Solutions</h3>
                                                    <p>
                                                        Transform your real estate business with cutting-edge digital tools for streamlined property management, marketing, and customer engagement.
                                                    </p>
                                                    <div className="service-btn">
                                                        <Link to="/project" onClick={e=>scrollTop()} className="link-btn">View Details</Link>
                                                        <Link to="/project" onClick={e=>scrollTop()} className="arrow-icon"><img src="/assets/img/icon/arrow.svg" alt="arrow icon" /></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay=".5s">
                                            <div className="service-box-items style-2 mt-0">
                                                <div className="icon">
                                                    <img src="/assets/img/icon/03.svg" alt="Fintech icon" />
                                                </div>
                                                <div className="content">
                                                    <h3>Fintech Innovations</h3>
                                                    <p>
                                                        We provide secure, scalable fintech solutions to enhance payment systems, lending platforms, and digital banking services that drive customer satisfaction and growth.
                                                    </p>
                                                    <div className="service-btn">
                                                        <Link to="/project" onClick={e=>scrollTop()} className="link-btn">View Details</Link>
                                                        <Link to="/project" onClick={e=>scrollTop()} className="arrow-icon"><img src="/assets/img/icon/arrow.svg" alt="arrow icon" /></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay=".7s">
                                            <div className="service-box-items style-2 mt-0">
                                                <div className="icon">
                                                    <img src="/assets/img/icon/04.svg" alt="Healthtech icon" />
                                                </div>
                                                <div className="content">
                                                    <h3>Healthtech Development</h3>
                                                    <p>
                                                        Revolutionize healthcare with our tech-driven solutions that prioritize patient engagement, telemedicine, and digital health records management.
                                                    </p>
                                                    <div className="service-btn">
                                                        <Link to="/project" onClick={e=>scrollTop()} className="link-btn">View Details</Link>
                                                        <Link to="/project" onClick={e=>scrollTop()} className="arrow-icon"><img src="/assets/img/icon/arrow.svg" alt="arrow icon" /></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay=".3s">
                                            <div className="service-box-items style-2 mt-0">
                                                <div className="icon">
                                                    <img src="/assets/img/icon/05.svg" alt="Ecommerce icon" />
                                                </div>
                                                <div className="content">
                                                    <h3>Ecommerce Solutions</h3>
                                                    <p>
                                                        From custom online stores to enhanced UX design, we build ecommerce platforms that scale and convert, ensuring seamless customer experiences.
                                                    </p>
                                                    <div className="service-btn">
                                                        <Link to="/project" onClick={e=>scrollTop()} className="link-btn">View Details</Link>
                                                        <Link to="/project" onClick={e=>scrollTop()} className="arrow-icon"><img src="/assets/img/icon/arrow.svg" alt="arrow icon" /></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay=".5s">
                                            <div className="service-box-items style-2 mt-0">
                                                <div className="icon">
                                                    <img src="/assets/img/icon/06.svg" alt="Edtech icon" />
                                                </div>
                                                <div className="content">
                                                    <h3>Edtech Platforms</h3>
                                                    <p>
                                                        Enhance learning experiences with engaging, scalable edtech platforms that bring education to the digital age and empower learners everywhere.
                                                    </p>
                                                    <div className="service-btn">
                                                        <Link to="/project" onClick={e=>scrollTop()} className="link-btn">View Details</Link>
                                                        <Link to="/project" onClick={e=>scrollTop()} className="arrow-icon"><img src="/assets/img/icon/arrow.svg" alt="arrow icon" /></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay=".7s">
                                            <div className="service-box-items style-2 mt-0">
                                                <div className="icon">
                                                    <img src="/assets/img/icon/07.svg" alt="Marketing/adtech icon" />
                                                </div>
                                                <div className="content">
                                                    <h3>Marketing & AdTech</h3>
                                                    <p>
                                                        Drive customer engagement and optimize ad campaigns with our data-driven marketing and adtech solutions tailored to achieve impactful results.
                                                    </p>
                                                    <div className="service-btn">
                                                        <Link to="/project" onClick={e=>scrollTop()} className="link-btn">View Details</Link>
                                                        <Link to="/project" onClick={e=>scrollTop()} className="arrow-icon"><img src="/assets/img/icon/arrow.svg" alt="arrow icon" /></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </> : <>
                                        <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay=".3s">
                                            <div className="service-box-items style-2 mt-0">
                                                <div className="icon">
                                                    <img src="/assets/img/icon/01.svg" alt="Gamedev icon" />
                                                </div>
                                                <div className="content">
                                                    <h3>Game Development</h3>
                                                    <p>
                                                        From concept to completion, we bring immersive game experiences to life with stunning graphics, engaging gameplay, and cross-platform compatibility.
                                                    </p>
                                                    <div className="service-btn">
                                                        <Link to="/project" onClick={e=>scrollTop()} className="link-btn">View Details</Link>
                                                        <Link to="/project" onClick={e=>scrollTop()} className="arrow-icon"><img src="/assets/img/icon/arrow.svg" alt="arrow icon" /></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay=".5s">
                                            <div className="service-box-items style-2 mt-0">
                                                <div className="icon">
                                                    <img src="/assets/img/icon/03.svg" alt="Automotive icon" />
                                                </div>
                                                <div className="content">
                                                    <h3>Automotive Tech</h3>
                                                    <p>
                                                        Innovate in the automotive sector with solutions that integrate IoT, connected car systems, and autonomous tech to revolutionize the driving experience.
                                                    </p>
                                                    <div className="service-btn">
                                                        <Link to="/project" onClick={e=>scrollTop()} className="link-btn">View Details</Link>
                                                        <Link to="/project" onClick={e=>scrollTop()} className="arrow-icon"><img src="/assets/img/icon/arrow.svg" alt="arrow icon" /></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                            }

                        </div>

                        <div className="page-nav-wrap pt-5 text-center wow fadeInUp" data-wow-delay=".3s">
                            <ul>
                                <li><Link onClick={e => setCurrentPage(1)} className="page-numbers icon" to=""><i className="fa-solid fa-arrow-left-long" /></Link></li>
                                <li><Link onClick={e => setCurrentPage(1)} className="page-numbers" to="">01</Link></li>
                                <li><Link onClick={e => setCurrentPage(2)} className="page-numbers" to="">02</Link></li>
                                <li><Link onClick={e => setCurrentPage(2)} className="page-numbers icon" to=""><i className="fa-solid fa-arrow-right-long" /></Link></li>
                            </ul>
                        </div>
                    </div>
                </section>


            </div>



        </>
    )
}

export default Service

