import React from 'react'
import { Link } from 'react-router-dom'

const Project = () => {
    return (
        <div>
            <div>
                {/* Breadcrumb Section Start */}
                <div className="breadcrumb-wrapper section-bg bg-cover" style={{ backgroundImage: 'url("/assets/img/icon/breadcrumb-shape.webp")' }}>
                    <div className="arrow-shape">
                        <img src="/assets/img/icon/arrow-shape.webp" alt="img" />
                    </div>
                    <div className="circle-shape">
                        <img src="/assets/img/icon/circle-shape.webp" alt="img" />
                    </div>
                    <div className="container">
                        <div className="page-heading">
                            <div className="breadcrumb-sub-title">
                                <h1 className="wow fadeInUp" data-wow-delay=".3s">Our Recent Project </h1>
                            </div>
                            <ul className="breadcrumb-items wow fadeInUp" data-wow-delay=".5s">
                                <li>
                                    <Link to="index">
                                        Home
                                    </Link>
                                </li>
                                <li>
                                    <i className="fa-solid fa-angles-right" />
                                </li>
                                <li>
                                    Recent Project
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="marquee-section fix">
                        <div className="mycustom-marque">
                            <div className="scrolling-wrap">
                                <div className="comm">
                                    <div className="cmn-textslide textitalick text-custom-storke">Recent Project</div>
                                    <div className="cmn-textslide textitalick">Recent Project</div>
                                    <div className="cmn-textslide textitalick text-custom-storke">Recent Project</div>
                                </div>
                                <div className="comm">
                                    <div className="cmn-textslide textitalick text-custom-storke">Recent Project</div>
                                    <div className="cmn-textslide textitalick">Recent Project</div>
                                    <div className="cmn-textslide textitalick text-custom-storke">Recent Project</div>
                                </div>
                                <div className="comm">
                                    <div className="cmn-textslide textitalick text-custom-storke">Recent Project</div>
                                    <div className="cmn-textslide textitalick">Recent Project</div>
                                    <div className="cmn-textslide textitalick text-custom-storke">Recent Project</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* News Section Start */}

                <section className="service-section fix section-padding" style={{ paddingTop: "50px" }}>
                    <h2 style={{ textAlign: 'center', marginBottom: '0px' }}>
                        Our Latest Project
                    </h2>
                    <p style={{ textAlign: 'center', marginBottom: '50px' }}>
                        Innovating across industries with tailored digital solutions that enhance user experiences and drive business success
                    </p>
                    {/* <h2 className='text-center mb-5'></h2> */}
                    <div className="container">
                        <div className="row g-4">
                            <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp mb-4" data-wow-delay=".3s">
                                <div className="news-box-items mt-0">
                                    <div className="news-image">
                                        <img src="/assets/img/projects/1.png" alt="img" />
                                        <img src="/assets/img/projects/1.png" alt="img" />
                                    </div>
                                    <div className="news-content">
                                        <p>Real Estate-Web App</p>
                                        <h3><Link to="https://hously-landing.vercel.app/" target='_blank'>Web App for Real Estate Business</Link></h3>
                                        <div className="news-btn">
                                            <Link to="https://hously-landing.vercel.app/" target='_blank' className="link-btn">View Details</Link>
                                            <Link to="https://hously-landing.vercel.app/" target='_blank' className="arrow-icon"><img src="/assets/img/icon/arrow.svg" alt="img" /></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp mb-4" data-wow-delay=".7s">
                                <div className="news-box-items mt-0">
                                    <div className="news-image">
                                        <img src="/assets/img/projects/3.png" alt="img" />
                                        <img src="/assets/img/projects/3.png" alt="img" />
                                    </div>
                                    <div className="news-content">
                                        <p>Health</p>
                                        <h3><Link to="https://gym-store.axiomthemes.com/" target='_blank'>Ultimate Gym Center -Web App</Link></h3>
                                        <div className="news-btn">
                                            <Link to="https://gym-store.axiomthemes.com/" target='_blank' className="link-btn">View Details</Link>
                                            <Link to="https://gym-store.axiomthemes.com/" target='_blank' className="arrow-icon"><img src="/assets/img/icon/arrow.svg" alt="img" /></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp mb-4" data-wow-delay=".3s">
                                <div className="news-box-items mt-0">
                                    <div className="news-image">
                                        <img src="/assets/img/projects/4.png" alt="img" />
                                        <img src="/assets/img/projects/4.png" alt="img" />
                                    </div>
                                    <div className="news-content">
                                        <p>Web App </p>
                                        <h3><Link target='_blank' to="https://blend-react.vercel.app/">Business Management System</Link></h3>
                                        <div className="news-btn">
                                            <Link to="https://blend-react.vercel.app/" className="link-btn">View Details</Link>
                                            <Link to="https://blend-react.vercel.app/" className="arrow-icon"><img src="/assets/img/icon/arrow.svg" alt="img" /></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp mb-4" data-wow-delay=".5s">
                                <div className="news-box-items mt-0">
                                    <div className="news-image">
                                        <img src="/assets/img/projects/5.png" alt="img" />
                                        <img src="/assets/img/projects/5.png" alt="img" />
                                    </div>
                                    <div className="news-content">
                                        <p>Blockchain</p>
                                        <h3><Link to="https://chaintusker.com/" target='_blank'>Web3 Digital Service Marketplace</Link></h3>
                                        <div className="news-btn">
                                            <Link to="https://chaintusker.com/" target='_blank' className="link-btn">View Details</Link>
                                            <Link to="https://chaintusker.com/" target='_blank' className="arrow-icon"><img src="/assets/img/icon/arrow.svg" alt="img" /></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp mb-4" data-wow-delay=".7s">
                                <div className="news-box-items mt-0">
                                    <div className="news-image">
                                        <img src="/assets/img/projects/6.png" alt="img" />
                                        <img src="/assets/img/projects/6.png" alt="img" />
                                    </div>
                                    <div className="news-content">
                                        <p>Blockchain</p>
                                        <h3><Link to="https://p2e-jackpot.vercel.app/" target='_blank'>P2E Crypto Gaming Plateform</Link></h3>
                                        <div className="news-btn">
                                            <Link to="https://p2e-jackpot.vercel.app/" target='_blank' className="link-btn">View Details</Link>
                                            <Link to="https://p2e-jackpot.vercel.app/" target='_blank' className="arrow-icon"><img src="/assets/img/icon/arrow.svg" alt="img" /></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp mb-4" data-wow-delay=".5s">
                                <div className="news-box-items mt-0">
                                    <div className="news-image">
                                        <img src="/assets/img/projects/2.png" alt="img" />
                                        <img src="/assets/img/projects/2.png" alt="img" />
                                    </div>
                                    <div className="news-content">
                                        <p>Blockchain </p>
                                        <h3><Link to="https://momo-v2-test.vercel.app/#/swap" target='_blank'>Fork of Uniswap V3 as MOMO Swap </Link></h3>
                                        <div className="news-btn">
                                            <Link to="https://momo-v2-test.vercel.app/#/swap" target='_blank' className="link-btn">View Details</Link>
                                            <Link to="https://momo-v2-test.vercel.app/#/swap" target='_blank' className="arrow-icon"><img src="/assets/img/icon/arrow.svg" alt="img" /></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="page-nav-wrap pt-5 text-center wow fadeInUp" data-wow-delay=".3s">
                            <ul>
                                <li><Link className="page-numbers icon" to="#"><i className="fa-solid fa-arrow-left-long" /></Link></li>
                                <li><Link className="page-numbers" to="#">01</Link></li>
                                {/* <li><Link className="page-numbers" to="#">02</Link></li>
                                <li><Link className="page-numbers" to="#">03</Link></li> */}
                                <li><Link className="page-numbers icon" to="#"><i className="fa-solid fa-arrow-right-long" /></Link></li>
                            </ul>
                        </div>
                    </div>
                </section>
            </div>


        </div>
    )
}

export default Project