import React, { useEffect, useState } from 'react'
import { BrowserRouter, Route, Link, Routes } from 'react-router-dom'
import Preloader from "./Components/Preloader"
import PageProperties from './Components/PageProperties'
import Footer from './Components/Footer'
import OffCanvas from './Components/OffCanvas'
import Header from './Components/Header'
import Home from './Page/Home'
import About from './Page/About'
import Service from './Page/Service'
import Project from './Page/Project'
// import ProjectDetails from './Page/ServiceDetails'
// import Blog from './Page/Blog'
// import BlogDetails from './Page/BlogDetails'
import NotFind from './Page/404'
import Faq from './Page/Faq'
import Contact from './Page/Contact'
import WebDevelopment from './Page/services/WebDevelopment'
import BlockchainDevelopment from './Page/services/BlockchainDevelopment'
import GraphicsDesign from './Page/services/GraphicsDesign'
import DigitalMarketing from './Page/services/DigitalMarketing'
import CookieConsent, { Cookies } from "react-cookie-consent";
function App() {



  const [isOpen, setIsOpen] = useState(false);

  const [expired, setexpired] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setexpired(true)
    }, 1200);

  }, [])




  return (
    <>
      <div className='body-bg'>
        <div>
          <BrowserRouter>

            {/* Preloader Start */}
            {
              !expired ?
                <Preloader />
                : ''
            }
            {/*Page  Properties */}
            <PageProperties />
            {/* OffCanvas Area Start */}
            <OffCanvas isOpen={isOpen} setIsOpen={setIsOpen} />
            {/* Header Section Start */}
            <Header isOpen={isOpen} setIsOpen={setIsOpen} />





            <Routes>

              <Route path='/' Component={Home} />
              <Route path='/about-us' Component={About} />
              <Route path='/services' Component={Service} />
              {/* <Route path='/service-details' Component={ProjectDetails} /> */}

              <Route path='/web-development' Component={WebDevelopment} />
              <Route path='/blockchain-development' Component={BlockchainDevelopment} />
              <Route path='/graphics-design' Component={GraphicsDesign} />
              <Route path='/digital-marketing' Component={DigitalMarketing} />

              <Route path='/project' Component={Project} />
              {/* <Route path='/blog' Component={BlogDetails} /> */}
              <Route path='/faq' Component={Faq} />
              <Route path='/contact' Component={Contact} />
              <Route path='/404' Component={NotFind} />
              <Route path='*' Component={NotFind} /> {/* Catch-all route for undefined paths */}

            </Routes>



            {/* Footer Section Start */}
            <Footer />
          </BrowserRouter>


          <CookieConsent
            location="bottom"
            buttonText="Accept"
            cookieName="devunicornlabs"
            style={{ background: "#000" }}
            buttonStyle={{ color: "#fff", fontSize: "13px", background:'#f90052' }}
            expires={150}
          >
            This website uses cookies to enhance the user experience.{" "}
            <span style={{ fontSize: "10px" }}>DevUnicornLabs</span>
          </CookieConsent>
        </div>

      </div>
    </>

  );
}

export default App;
