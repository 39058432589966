import React from 'react'

const Preloader = () => {
    return (

        <div  className="preloader">
            <div className="animation-preloader">
                <div className="spinner">
                </div>
                <div className="txt-loading">
                    <img style={{ maxWidth: '50%' }} src="/assets/img/logo/icon.png" />
                </div>
                <p className="text-center">Loading</p>
            </div>
            <div className="loader">
                <div className="row">
                    <div className="col-3 loader-section section-left">
                        <div className="bg" />
                    </div>
                    <div className="col-3 loader-section section-left">
                        <div className="bg" />
                    </div>
                    <div className="col-3 loader-section section-right">
                        <div className="bg" />
                    </div>
                    <div className="col-3 loader-section section-right">
                        <div className="bg" />
                    </div>
                </div>
            </div>
        </div>
        )
}

export default Preloader