import React from 'react'

const DigitalMarketing = () => {
    return (
        <div>
            <div>
                <div className="breadcrumb-wrapper section-bg bg-cover" style={{ backgroundImage: 'url("/assets/img/icon/breadcrumb-shape.webp")' }}>
                    <div className="arrow-shape">
                        <img src="/assets/img/icon/arrow-shape.webp" alt="img" />
                    </div>
                    <div className="circle-shape">
                        <img src="/assets/img/icon/circle-shape.webp" alt="img" />
                    </div>
                    <div className="container">
                        <div className="page-heading">
                            <div className="breadcrumb-sub-title">
                                <h1 className="wow fadeInUp" data-wow-delay=".3s">Digital Marketing</h1>
                            </div>
                            <ul className="breadcrumb-items wow fadeInUp" data-wow-delay=".5s">
                                <li>
                                    <a href="../index.html">
                                        Home
                                    </a>
                                </li>
                                <li>
                                    <i className="fa-solid fa-angles-right" />
                                </li>
                                <li>
                                    Digital Marketing
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="marquee-section fix">
                        <div className="mycustom-marque">
                            <div className="scrolling-wrap">
                                <div className="comm">
                                    <div className="cmn-textslide textitalick text-custom-storke">Services Details</div>
                                    <div className="cmn-textslide textitalick">Services Details</div>
                                    <div className="cmn-textslide textitalick text-custom-storke">Services Details</div>
                                </div>
                                <div className="comm">
                                    <div className="cmn-textslide textitalick text-custom-storke">Services Details</div>
                                    <div className="cmn-textslide textitalick">Services Details</div>
                                    <div className="cmn-textslide textitalick text-custom-storke">Services Details</div>
                                </div>
                                <div className="comm">
                                    <div className="cmn-textslide textitalick text-custom-storke">Services Details</div>
                                    <div className="cmn-textslide textitalick">Services Details</div>
                                    <div className="cmn-textslide textitalick text-custom-storke">Services Details</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Service Details Section Start */}
                <section className="service-details-section fix section-padding">
                    <div className="container">
                        <div className="service-details-wrapper">
                            <div className="row g-4">
                                <div className="col-xl-4 col-lg-4 col-md-6">
                                    <div className="details-content">
                                        <h2>Digital Marketing</h2>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-6">
                                    <div className="details-content">
                                        <p>Our digital marketing solutions are crafted to enhance your brand’s online visibility and drive sustainable growth. With a dedicated team of strategists, marketers, and analysts, we employ a holistic approach tailored to meet your business goals.</p>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-6 ps-lg-5">
                                    <div className="details-content">
                                        <p>From increasing brand awareness to optimizing conversion rates, our digital marketing services are designed to connect your brand with your target audience effectively and meaningfully.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="details-image">
                                        <img src="/assets/img/details-1.webp" alt="Digital Marketing" />
                                    </div>
                                    <div className="details-content">
                                        <h3 className="mb-3">Service Overview</h3>
                                        <p>
                                            Our digital marketing services encompass a wide range of strategies and tools, including search engine optimization (SEO), social media management, content marketing, and pay-per-click (PPC) advertising. We are committed to helping your business achieve a dominant online presence and meaningful engagement with your audience.
                                        </p>
                                        <div className="details-list-items">
                                            <ul>
                                                <li>+ Search Engine Optimization (SEO)</li>
                                                <li>+ Social Media Management</li>
                                                <li>+ Content Creation &amp; Marketing</li>
                                            </ul>
                                            <ul>
                                                <li>+ Pay-Per-Click (PPC) Advertising</li>
                                                <li>+ Email Marketing</li>
                                                <li>+ Analytics &amp; Reporting</li>
                                            </ul>
                                        </div>
                                        <h3 className="mb-3">Key Features</h3>
                                        <p>
                                            Our digital marketing services are built on insights and strategy. We analyze your industry, competitors, and audience to tailor a marketing approach that resonates with your target market. From creating engaging social media posts to executing data-driven ad campaigns, we ensure that every aspect of your digital presence aligns with your brand’s goals.
                                        </p>
                                    </div>
                                    <div className="details-image-2">
                                        <div className="row">
                                            <div className="col-lg-8">
                                                <img src="/assets/img/details-4.webp" alt="SEO Optimization" />
                                            </div>
                                            <div className="col-lg-4">
                                                <img src="/assets/img/details-5.webp" alt="Social Media Strategy" />
                                            </div>
                                        </div>
                                    </div>
                                    <p>
                                        We create customized strategies for each of our clients based on their specific needs and goals. From managing social media channels to developing engaging content, our approach is data-driven and results-oriented. With our SEO expertise, we work to improve your site’s ranking and drive organic traffic to build a loyal customer base.
                                    </p>
                                    <p className="mt-2">
                                        Our team also offers PPC services that provide measurable results, helping you reach potential customers efficiently. Combined with detailed analytics and reporting, we help you track your progress, optimize campaigns, and refine strategies to maximize your marketing ROI.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> 
                {/* Faq Section Start */}
                <section className="faq-section fix section-padding">
                    <div className="container">
                        <div className="faq-wrapper">
                            <div className="row g-4">
                                <div className="col-lg-6">
                                    <div className="faq-image">
                                        <img src="/assets/img/faq2.webp" alt="Digital Marketing FAQ" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="faq-content">
                                        <div className="faq-accordion">
                                            <div className="accordion" id="accordion">
                                                <div className="accordion-item mb-4 wow fadeInUp" data-wow-delay=".2s">
                                                    <h5 className="accordion-header">
                                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#faq1" aria-expanded="true" aria-controls="faq1">
                                                            What digital marketing services do you offer?
                                                        </button>
                                                    </h5>
                                                    <div id="faq1" className="accordion-collapse show" data-bs-parent="#accordion">
                                                        <div className="accordion-body">
                                                            We provide a comprehensive suite of digital marketing services, including search engine optimization (SEO), social media management, content marketing, pay-per-click (PPC) advertising, email marketing, and analytics. Each service is customized to enhance your brand’s online presence and drive results.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item mb-4 wow fadeInUp" data-wow-delay=".4s">
                                                    <h5 className="accordion-header">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq2" aria-expanded="false" aria-controls="faq2">
                                                            How long does it take to see results from digital marketing?
                                                        </button>
                                                    </h5>
                                                    <div id="faq2" className="accordion-collapse collapse" data-bs-parent="#accordion">
                                                        <div className="accordion-body">
                                                            The timeline for results varies depending on the specific services and goals. SEO, for example, typically takes 3-6 months to see significant improvement, while PPC can yield quicker results. We provide regular performance reports so you can track your progress.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item mb-4 wow fadeInUp" data-wow-delay=".6s">
                                                    <h5 className="accordion-header">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq3" aria-expanded="false" aria-controls="faq3">
                                                            How do you approach social media management?
                                                        </button>
                                                    </h5>
                                                    <div id="faq3" className="accordion-collapse collapse" data-bs-parent="#accordion">
                                                        <div className="accordion-body">
                                                            We start by understanding your brand’s voice and target audience. Our team creates a content calendar tailored to your goals, designs engaging posts, and actively manages interactions. We also analyze performance metrics to continuously refine our strategy for maximum impact.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item mb-4 wow fadeInUp" data-wow-delay=".7s">
                                                    <h5 className="accordion-header">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq4" aria-expanded="false" aria-controls="faq4">
                                                            What makes your digital marketing approach different?
                                                        </button>
                                                    </h5>
                                                    <div id="faq4" className="accordion-collapse collapse" data-bs-parent="#accordion">
                                                        <div className="accordion-body">
                                                            Our approach is highly personalized and data-driven. We combine strategic planning with creativity and leverage advanced analytics to optimize every campaign. Our team focuses on measurable outcomes that align with your business objectives, ensuring high ROI.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item wow fadeInUp" data-wow-delay=".8s">
                                                    <h5 className="accordion-header">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq5" aria-expanded="false" aria-controls="faq5">
                                                            Can you help with content creation for my business?
                                                        </button>
                                                    </h5>
                                                    <div id="faq5" className="accordion-collapse collapse" data-bs-parent="#accordion">
                                                        <div className="accordion-body">
                                                            Absolutely! Our content creation services include blog writing, social media posts, graphic design, video production, and more. We create content that is not only engaging but also optimized for search engines and social media algorithms to increase your reach and engagement.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>


        </div>
    )
}

export default DigitalMarketing