import React from 'react'

const PageProperties = () => {
    return (
        <>
            {/* Mouse coursor */}
            <div className="mouse-cursor cursor-outer" />
            <div className="mouse-cursor cursor-inner" />
            {/* Back To Top start */}
            <button id="back-top" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} className="back-to-top">
                <i className="fa-solid fa-arrow-up" />
            </button></>
    )
}

export default PageProperties